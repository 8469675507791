import styled from '@emotion/styled'

export const NavbarSection = styled.div`
  background: #101213;
  padding-block: 50px;
`

export const NavbarContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    @media (max-width: 768px) {
      display: none;
    }
  }
`

export const NavbarLogo = styled.h2`
  cursor: pointer;
  font-size: 25px;
  font-weight: 300;

  i {
    color: #0bc5ea;
  }
`

export const NavbarItems = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`

export const NavbarItem = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-inline: 20px;
  opacity: 0.8;
  transition: ease-in-out 0.3s;

  i {
    margin-right: 10px;
  }

  &:hover {
    opacity: 1;
  }
`

export const NavbarMobileItem = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    cursor: pointer;
    font-size: 20px;
  }
`
