import styled from '@emotion/styled'

export const BenefitSection = styled.div`
  background: #101213;
  padding-block: 100px;
`

export const BenefitContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  button {
    width: fit-content;
    border-radius: 24px;
  }
`

export const BenefitTitle = styled.h3`
  color: #0bc5ea;
  font-size: 30px;
  font-weight: 600;
  max-width: 500px;

  @media (max-width: 768px) {
    font-size: 25px;
  }
`

export const BenefitDescription = styled.p`
  margin-block: 20px;
  font-weight: 300;
  max-width: 500px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

export const BenefitImage = styled.div`
  display: flex;
  justify-content: center;
`
