import { Container, Image, useDisclosure } from '@chakra-ui/react'

import Icon from '../../styles/shared/Icon'
import Button from '../../styles/shared/Button'

import {
  NavbarContent,
  NavbarItem,
  NavbarItems,
  NavbarMobileItem,
  NavbarSection,
} from '../../styles/shared/Navbar'

import Router from 'next/router'
import NavbarMobile from './NavbarMobile'

export default function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <NavbarSection id="Navbar">
      <Container maxWidth="container.xl">
        <NavbarContent>
          <Image
            src="/logo-white.png"
            width="auto"
            height="50px"
            alt="CenturiumHost"
            style={{ cursor: 'pointer' }}
            onClick={() => Router.push('/')}
            onDragStart={(event) => event.preventDefault()}
          />
          <NavbarItems>
            <NavbarItem onClick={() => Router.push('/')}>
              <Icon className="fa-solid fa-house" /> Início
            </NavbarItem>
            <NavbarItem onClick={() => Router.push('/vps')}>
              <Icon className="fa-solid fa-server" /> Servidores VPS
            </NavbarItem>
            <NavbarItem onClick={() => Router.push('/dedicated')}>
              <Icon className="fa-solid fa-network-wired" /> Dedicado
            </NavbarItem>
            <NavbarItem onClick={() => Router.push('/minecraft')}>
              <Icon className="fa-solid fa-cube" /> Minecraft
            </NavbarItem>
          </NavbarItems>
          <NavbarMobileItem onClick={onOpen}>
            <Icon className="fa-solid fa-bars" />
          </NavbarMobileItem>
          <Button iconCenter onClick={() => Router.push('/auth/login')}>
            <Icon className="fa-solid fa-user" size="18px" /> Área do Cliente
          </Button>
        </NavbarContent>
      </Container>
      <NavbarMobile isOpen={isOpen} onClose={onClose} />
    </NavbarSection>
  )
}
