import { Container, Image, SimpleGrid } from '@chakra-ui/react'

import {
  FooterBottomContent,
  FooterBottomCopyright,
  FooterBottomSection,
  FooterItem,
  FooterItems,
  FooterItemTitle,
  FooterLink,
  FooterSection,
} from '../../styles/shared/Footer'

import Router from 'next/router'

export default function Footer() {
  return (
    <>
      <FooterSection>
        <Container maxWidth="container.xl">
          <SimpleGrid
            columns={{ base: 1, md: 4 }}
            alignItems="center"
            spacing={10}
          >
            <Image
              src="/logo-white.png"
              width="auto"
              height="80px"
              alt="CenturiumHost"
              style={{ cursor: 'pointer' }}
              onClick={() => Router.push('/')}
              onDragStart={(event) => event.preventDefault()}
            />
            <FooterItem>
              <FooterItemTitle>Suporte</FooterItemTitle>
              <FooterItems>
                <FooterLink onClick={() => Router.push('/')}>
                  Abrir ticket
                </FooterLink>
                <FooterLink onClick={() => Router.push('/')}>
                  Perguntas Frequentes
                </FooterLink>
                <FooterLink onClick={() => Router.push('/')}>
                  Termos e Serviços
                </FooterLink>
              </FooterItems>
            </FooterItem>
            <FooterItem>
              <FooterItemTitle>Sociais</FooterItemTitle>
              <FooterItems>
                <FooterLink onClick={() => Router.push('/')}>
                  Discord
                </FooterLink>
                <FooterLink onClick={() => Router.push('/')}>
                  Whatsapp
                </FooterLink>
                <FooterLink onClick={() => Router.push('/')}>
                  Instagram
                </FooterLink>
              </FooterItems>
            </FooterItem>
            <FooterItem>
              <FooterItemTitle>Contatos</FooterItemTitle>
              <FooterItems>
                <FooterLink onClick={() => Router.push('/')}>
                  Discord
                </FooterLink>
                <FooterLink onClick={() => Router.push('/')}>
                  Whatsapp
                </FooterLink>
                <FooterLink onClick={() => Router.push('/')}>
                  Instagram
                </FooterLink>
              </FooterItems>
            </FooterItem>
          </SimpleGrid>
        </Container>
      </FooterSection>
      <FooterBottomSection>
        <Container maxWidth="container.xl">
          <FooterBottomContent>
            <FooterBottomCopyright>
              © {new Date().getFullYear()} <strong>CenturiumHost</strong>. Todos
              os direitos reservados.
            </FooterBottomCopyright>
            <FooterLink href="https://moonkode.com.br" target="_blank">
              <Image
                src="/moonkode.png"
                width="auto"
                height="64px"
                alt="Moonkode"
                onDragStart={(event) => event.preventDefault()}
              />
            </FooterLink>
          </FooterBottomContent>
        </Container>
      </FooterBottomSection>
    </>
  )
}
