import Navbar from '../../components/shared/Navbar'
import Footer from '../../components/shared/Footer'

import { motion, AnimatePresence } from 'framer-motion'

export default function Layout({ children }: any) {
  return (
    <>
      <Navbar />
      <AnimatePresence>
        <motion.div
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <main>{children}</main>
        </motion.div>
      </AnimatePresence>
      <Footer />
    </>
  )
}
