import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react'

import styled from '@emotion/styled'

import { NavbarItem, NavbarItems } from '@styles/shared/Navbar'

export const NavbarMobileSection = styled(Drawer)``

export const NavbarMobileOverlay = styled(DrawerOverlay)``

export const NavbarMobileContent = styled(DrawerContent)`
  background: #1a1c1e;
`

export const NavbarMobileCloseButton = styled(DrawerCloseButton)`
  margin-top: 10px;
`

export const NavbarMobileHeader = styled(DrawerHeader)``

export const NavbarMobileBody = styled(DrawerBody)`
  padding-block: 50px;
`

export const NavbarMobileTitle = styled.h3`
  font-size: 25px;
  font-weight: 700;
`

export const NavbarMobileItems = styled(NavbarItems)`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`

export const NavbarMobileItem = styled(NavbarItem)`
  @media (max-width: 768px) {
    margin-inline: 0;
    margin-block: 5px;
  }
`
