import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/react'

import styled from '@emotion/styled'

export const QuestionsSection = styled.div`
  padding-block: 100px;
  background: #101213;
`

export const QuestionsTitle = styled.h2`
  font-size: 30px;
  font-weight: 600;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 25px;
  }
`

export const QuestionsDescription = styled.p`
  font-weight: 300;
  text-align: center;
  opacity: 0.8;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

export const Question = styled(AccordionItem)`
  background: #1a1c1e;
  border-top-width: 0;
  border-bottom-width: 0px !important;
  box-shadow: none !important;
  border-radius: 12px;
  margin-bottom: 20px;
`

export const QuestionButton = styled(AccordionButton)`
  font-size: 16px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 12px;
  padding-block: 20px;
  transition: ease-in-out 0.3s;

  svg {
    color: #0bc5ea;
  }

  @media (max-width: 768px) {
    font-size: 15px;
  }
`

export const QuestionPanel = styled(AccordionPanel)`
  font-size: 14px;
  font-weight: 300;
  opacity: 0.8;
`
