import styled from '@emotion/styled'

export default styled.button<{
  fill?: boolean
  margin?: string
  padding?: string
  iconCenter?: boolean
}>`
  width: ${(props) => (props.fill ? '100%' : 'auto')};
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0bc5ea;
  padding-block: ${(props) => (props.padding ? '' : '10px')};
  padding-inline: ${(props) => (props.padding ? '' : '20px')};
  padding: ${(props) => props.padding};
  border-radius: 6px;
  transition: ease-in-out 0.3s;
  margin: ${(props) => props.margin || '0'};

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  i {
    margin-right: ${(props) => (props.iconCenter ? '5px' : '0px')};
  }

  &:hover {
    background: #00a3c4;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`
