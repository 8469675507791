import { Accordion, AccordionIcon, Box, Container } from '@chakra-ui/react'

import {
  Question,
  QuestionButton,
  QuestionPanel,
  QuestionsDescription,
  QuestionsSection,
  QuestionsTitle,
} from '../../styles/shared/Question'

export default function Questions() {
  return (
    <QuestionsSection id="questions">
      <Container maxWidth="container.xl">
        <QuestionsTitle>Perguntas Frequentes</QuestionsTitle>
        <QuestionsDescription>
          Confira as perguntas frequentes relacionadas aos nosso serviços
        </QuestionsDescription>
        <Accordion defaultIndex={[0]} marginTop="80px" allowToggle>
          <Question>
            <h2>
              <QuestionButton>
                <Box flex="1" textAlign="left">
                  Como faço para solicitar um reembolso?
                </Box>
                <AccordionIcon />
              </QuestionButton>
            </h2>
            <QuestionPanel pb={4}>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quae,
              tenetur repellat? Beatae, pariatur? Accusamus assumenda
              accusantium veritatis nisi libero odio ut suscipit molestias
              dicta, non culpa recusandae consequuntur saepe obcaecati?
            </QuestionPanel>
          </Question>
          <Question>
            <h2>
              <QuestionButton>
                <Box flex="1" textAlign="left">
                  Como faço para contratar um servidor dedicado?
                </Box>
                <AccordionIcon />
              </QuestionButton>
            </h2>
            <QuestionPanel pb={4}>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quae,
              tenetur repellat? Beatae, pariatur? Accusamus assumenda
              accusantium veritatis nisi libero odio ut suscipit molestias
              dicta, non culpa recusandae consequuntur saepe obcaecati?
            </QuestionPanel>
          </Question>
          <Question>
            <h2>
              <QuestionButton>
                <Box flex="1" textAlign="left">
                  Os servidores dedicados possuem proteção DDoS?
                </Box>
                <AccordionIcon />
              </QuestionButton>
            </h2>
            <QuestionPanel pb={4}>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quae,
              tenetur repellat? Beatae, pariatur? Accusamus assumenda
              accusantium veritatis nisi libero odio ut suscipit molestias
              dicta, non culpa recusandae consequuntur saepe obcaecati?
            </QuestionPanel>
          </Question>
          <Question>
            <h2>
              <QuestionButton>
                <Box flex="1" textAlign="left">
                  Tenho suporte técnico 24 horas por dia?
                </Box>
                <AccordionIcon />
              </QuestionButton>
            </h2>
            <QuestionPanel pb={4}>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quae,
              tenetur repellat? Beatae, pariatur? Accusamus assumenda
              accusantium veritatis nisi libero odio ut suscipit molestias
              dicta, non culpa recusandae consequuntur saepe obcaecati?
            </QuestionPanel>
          </Question>
        </Accordion>
      </Container>
    </QuestionsSection>
  )
}
