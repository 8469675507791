import styled from '@emotion/styled'

export const FooterSection = styled.footer`
  background: #1a1c1e;
  padding-block: 50px;
`

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const FooterItem = styled.div`
  display: flex;
  flex-direction: column;
`

export const FooterItemTitle = styled.h5`
  margin-bottom: 10px;
  font-weight: 600;
`

export const FooterItems = styled.div`
  display: flex;
  flex-direction: column;
`

export const FooterLink = styled.a`
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  opacity: 0.8;

  &:hover {
    text-decoration: underline;
  }
`

export const FooterBottomSection = styled.div`
  background: #101213;
  padding-block: 10px;
`

export const FooterBottomContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const FooterBottomCopyright = styled.p`
  font-size: 15px;
`
