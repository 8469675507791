import styled from '@emotion/styled'

export const HeaderSection = styled.div`
  padding-block: 50px;
  padding-bottom: 0;
`

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const HeaderTitle = styled.h1`
  font-size: 50px;
  font-weight: 700;
  max-width: 700px;

  @media (max-width: 768px) {
    font-size: 30px;
  }
`

export const HeaderDescription = styled.p`
  margin-block: 20px;
  font-weight: 300;
  opacity: 0.8;
  max-width: 700px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

export const HeaderWave = styled.img`
  user-select: none;
`
