import { Container, Image, SimpleGrid } from '@chakra-ui/react'

import {
  BenefitContent,
  BenefitDescription,
  BenefitImage,
  BenefitSection,
  BenefitTitle,
} from '../../styles/components/home/Benefit'
import Button from '../../styles/shared/Button'
import Router from 'next/router'

export default function Benefit() {
  return (
    <BenefitSection id="benefit">
      <Container maxWidth="container.xl">
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          alignItems="center"
          spacing={10}
        >
          <BenefitImage>
            <Image
              src="/svgs/dedicated-server.svg"
              width="auto"
              height="400px"
              alt="Servidor dedicado"
              onDragStart={(event) => event.preventDefault()}
            />
          </BenefitImage>
          <BenefitContent>
            <BenefitTitle>
              Compre o seu servidor dedicado e receba rapidamente para começar o
              seu projeto
            </BenefitTitle>
            <BenefitDescription>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laborum
              cumque architecto commodi, quaerat in veritatis error quia sequi
              atque, dolore sapiente iusto incidunt! Id, laboriosam ipsum. Aut
              numquam molestiae perferendis.
            </BenefitDescription>
            <Button onClick={() => Router.push('/vps#plans')}>
              Começar agora
            </Button>
          </BenefitContent>
        </SimpleGrid>
      </Container>
    </BenefitSection>
  )
}
